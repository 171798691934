import React from "react"
import Layout from "../../../components/layout"
import EfemeridesM from "../../../components/efemeridesm"
import CommentsFB from "../../../components/Comments"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const EfeEnero = () => (
  <Layout>
    <GatsbySeo
      title="Efemérides - Julio"
      titleTemplate="%s | Eduardo Ceballos"
      description="Efemérides de Salta por Eduardo Ceballos"
      canonical="https://eduardoceballos.com/libros/efemerides/julio"
      openGraph={{
        url: "https://eduardoceballos.com/libros/efemerides/julio",
        title: "Efemérides - Julio | Eduardo Ceballos",
        description: "Efemérides de Salta por Eduardo Ceballos",
        images: [
          {
            url: `https://res.cloudinary.com/lagexpress/image/upload/v1597476577/strapi/tapalibroconozcasaltaatravesdesusefemerides_4670275357.jpg`,
            alt:
              "Tapa del libro del libro Conozca Salta a través de sus Efemérides",
          },
        ],
        site_name: "Eduardo Ceballos",
      }}
      twitter={{
        handle: "@PoetadeSalta",
        site: "@PoetadeSalta",
        cardType: "summary_large_image",
      }}
    />
    <h1>Conozca Salta a través de sus Efemérides – JULIO</h1>
    <hr />
    <EfemeridesM />
    <hr />
    <p>
      1 de julio de 1817: El capitán Eusebio Mollinedo, del ejército de Güemes,
      derrotó a los realistas en Pumahuasi, provincia de Salta.
    </p>
    <p>
      1 de julio de 1837: Nació en Salta, Segundo Linares. Fue ministro de
      gobierno, senador nacional por Salta de 1857 a 1880. Fundó el diario
      “Norte” en 1890. Fundó en Jujuy otro diario de poca duración “El
      Autonomista”. También fue docente. Falleció el 5 de junio de 1910.
    </p>
    <p>
      1 de julio de 1963: Nació en Salta, Enrique Aguilera. Folklorista que
      integró el conjunto Los Nocheros y en la actualidad canta en Los 4 de
      Salta.
    </p>
    <p>
      2 de julio de 1873: Nació en Salta, Nicolás López Isasmendi. Redactó el
      acta de fundación de la Junta de Investigaciones Históricas, Arqueológicas
      y Numismáticas de Salta, totalmente en verso. Ejerció la docencia. Fue
      secretario del Consejo General de Educación de la provincia. Falleció el 4
      de mayo de 1919.
    </p>
    <p>
      2 de julio de 1885:&nbsp;Nació en El Galpón, Departamento de Metán, en la
      provincia de Salta, Lorenzo Arias Valdez. Fue ordenado sacerdote por el
      Obispo Monseñor Matías Linares. Fue Vicario General, Capellán del Convento
      San Bernardo. Simpatizante de la causa radical, fue convencional por
      Salta. Falleció en Salta el 16 de enero de 1944.
    </p>
    <p>
      2 de julio de 1941: Nació en Salta, Patricio Gustavo Enrique Colombo
      Murúa. Abogado que se desempeñó como director de la Casa de Salta en Santa
      Cruz, profesor de Derecho Administrativo de la Universidad de Buenos
      Aires, secretario Académico y luego Decano de Ciencias Jurídicas de la
      Universidad Católica de Salta, Ministro de Educación y Cultura de Santa
      Cruz, Asesor docente del consejo Nacional de Educación, Director Nacional
      de Asuntos Universitarios, Secretario General de la Universidad Católica
      de Salta, casa de Altos Estudios de la que es su rector actualmente.
    </p>
    <p>
      2 de julio de 1947:&nbsp;Nació en Salta, Mary del Socorro Córdoba.
      Folkloróloga y pedagoga, que asesera cultural del Senado, Directora de
      Escuelas primarias de 1º categoría. Resultó la Mujer Cultural de 1992 por
      el CIOFF. Asesorapedagógica de la Escuela para Padres (ONG). Delegada
      provincial ante festivales folklóricos nacionales e internacionales. Fue
      Secretaria de Cultura de FERINOA. Ex-becaria de la OEA.
    </p>
    <p>
      2 de julio de 1984: Falleció en nuestra ciudad, a los 90 años de edad, la
      poetista Emma Solá de Solá . Publicó: “La madre del viento” (1928); “El
      agua que canta” (1922); “El sendero y la estrella” (1933); “El alma en la
      noche” (prosa-1947). Colaboró con diarios y revistas del país. En 1935
      ganó el primer premio con medalla de oro en el concurso convocado para
      realizar la letra al Himno del Señor del Milagro y Poesía a la Virgen del
      Milagro. Estas páginas son cantadas por el pueblo cada setiembre, cuando
      llegan las fiestas del milagro.
    </p>
    <p>
      3 de julio de 1890: Asumió como gobernador de Salta, don Pedro J. Frías,
      quien gobernó hasta el 1º de mayo de 1893.
    </p>
    <p>
      3 de julio de 1928: Murió en Salta, Benita Campos . Destacada docente y
      periodista. Fundó la revista Güemes en 1907, destinada a enaltecer la
      figura del Héroe Gaucho. Fundó, también La Asosiación Pro-Patria, que
      estaba integrada por reconocidas personalidades femeninas de Salta.
    </p>
    <p>
      3 de julio de 1937: Nació en Salta, Eleodoro Horacio Aguirre. Destacado
      compositor, autor de canciones folklóricas. Fue integrante de Los Cantores
      del Alba.
    </p>
    <p>
      3 de julio de 1949: Entran en funcionamiento los motores de la nueva usina
      eléctrica de AGAS en Metán. También en El Galpón, la provincia se hace
      cargo del suministro de energía eléctrica, que estaba en manos de la
      empresa Mulki.
    </p>
    <p>
      3 de julio de 1982: Murió el ex-gobernador de Salta, don Ricardo Joaquín
      Durand. Fue conocido popularmente como “El Hornero”, por la gran cantidad
      de viviendas, que hizo construir en su gobierno.
    </p>
    <p>
      4 de julio de 1809: El gobernador de la Intendencia de Salta del Tucumán
      solicitó y obtuvo del Virrey que destine a Güemes, quien se encontraba en
      su ciudad natal el uso de licencia, a la guarnición militar de Salta, a la
      que se incorporó con el grado de teniente.
    </p>
    <p>
      4 de julio de 1814: El capitán Güemes al frente de sus gauchos, derrotó a
      las tropas mandadas por el coronel Guillermo Marquiegui, en Cuesta Nevada,
      provincia de Salta.
    </p>
    <p>
      4 de julio de 1814: El coronel Juan Antonio Alvarez de Arenales, derrotó
      al brigadier realista Manuel Blanco, en Postrer Valle, provincia de Salta.
    </p>
    <p>
      4 de julio de 1870: El Naranjo fue declarada Capital del Departamento de
      Rosario de la Frontera.
    </p>
    <p>
      4 de julio de 1946: Se declaró Monumento Histórico, por Decreto Nº 2.233,
      “La Casa de Arias Rengel”. Está en La Florida 20 de la ciudad de Salta.
      Fue mandada a construir a mediados del siglo XVIII por el maestre de campo
      Féliz Arias Rengel y Heredia. Después due adquirida por el coronel José
      María Todd, querrero del Brasil y quien fue también gobernador de la
      provincia de Salta.
    </p>
    <p>
      4 de julio de 1946: Fue declarada Monumento Histórico por Decreto Nº
      2.233, la Casa de Otero. Está situada en la calle Caseros del 769 al 783
      entre la Florida e Ituzaingó. La hizo construir a mediados del siglo XVIII
      don Pedro José Otero, rico comerciante español radicado en Salta. Fue
      habitada en Salta por la familia Otero hasta fines del siglo XIX,
      perteneciendo luego al doctor Fortunaro Solá y Rincón Otero, cuya esposa
      la donó a la Sociedad de Beneficencia. Esta la vendió más tarde al doctor
      Jorge San Miguel. En esta casa nació en 1790 Miguel Otero, hijo del primer
      propietario, que cuando estudiaba en Chuquisaca encabezó el movimiento
      revolucionario en noviembre de 1810, en apoyo de la causa de mayo. Fue
      gobernador interino de la provincia de Salta.
    </p>
    <p>
      4 de julio de 1946: Fue declarada Monumento Histórico, por Decreto Nº
      2.233, la Casa de los Zorrilla. Construída en el siglo XVII. Allí nació el
      doctor Manuel Marcos Zorrilla y ha vivido su hermano el doctor Benjamín
      Zorrilla. La mandó construir su dueño, don Mateo Gómez Zorrilla. Tiempo
      después la compró doña Lucinda Quiroz Zorrilla. Está ubicada en Casareos
      776 al 790 en la ciudad de Salta.
    </p>
    <p>
      5 de julio de 1744: Nació en Rosario de Lerma, provincia de Salta, José
      Arias Rengel e Hidalgo. Se graduó de abogado en Chuquisaca, poco después
      de doctorarse en Teología en la Universidad de San Javier. Desempeñó
      funciones en la Real Audiencia de Charcas. Fue Auditor de guerra. Publicó
      los siguientes libros: “Información del Estado de las nuevas conquistas
      del Chaco” (1780); “Descripción del Gran Chaco Gualamba”; “Crónica de la
      expedición al Chaco” (1781); “Diario del coronel José Gabino Arias”
      (1782). Se lo considera como el primer cronista del Virreinato del Río de
      la Plata. Sus obras fueron publicadas recién un siglo después por el
      recopilador de la época, don Pedro de Angelis en Buenos Aires. Falleció en
      Salta en 1787.
    </p>
    <p>
      5 de julio de 1820: Güemes comunicó al Cabildo de Salta que pese a no
      contar con ayuda de las demás provincias ha rechazado una nueva invasión
      mientras el Ejército de los Andes se aprestaba a marchar hacia el Perú.
    </p>
    <p>
      5 de julio de 1820: El coronel doctor José Ignacio Gorriti, del ejército
      patriota, derrotó a los realistas mandados por el coronel Guillermo
      Marquiegui en Salta.
    </p>
    <p>
      5 de julio de 1936: Nació en Salta, el poeta Miguel A. Carreras. Publicó
      en versos: “Al alba de unos versos”, “Esta inutil memoria”, “Regreso en
      los días”, “Hierros paralelos” entre otros títulos. Fue galardonado en
      distinto certámenes.
    </p>
    <p>
      5 de julio de 1944: Nació en Salta, José Armando Caro Figueroa. Estudió
      enla escuela Urquiza y en el Colegio Nacional. A los 19 años se graduó de
      abogado en la Universidad Nacional de Tucumán. Desempeñó diversos cargos
      en la Corte de Justicia. Fue fundador del Colegio de Magistrados de Salta.
      En 1970, dirigió el diario Democracia. Se desempeñó como Subsecretario de
      Desarrollo Regional del Ministerio de Economía de la Nación (1985-1988).
      Fue asesor ejecutivo del Ministerio de Trabajo y Seguridad Social de
      España. Desde 1993 es el Ministro de Trabajo y Seguridad Social de la
      Nación. En Mayo de 1995, fue elegido Diputado Nacional por Salta, banca a
      la que luego, renunció.
    </p>
    <p>
      6 de julio de 1936: Nació en Salta, el poeta Sergio Rodríguez. Publicó en
      versos: “Semblanzas, y versos para Silvia”, “Ciudad del Oeste”. Fue
      director del Boletín Oficial de la Provincia de Salta. Ex presidente
      S.A.D.E. Salta; ex Asesor Cultural de la Municipalidad de Salra; El
      Concejo Deliberante de la Ciudad de Salta lo delcró Ciudadano Destacado
      por Mérito Artístico.
    </p>
    <p>
      6 de julio de 1951: Nació en Salta, Héctor Aníbal Aguirre poeta que
      publicó ellibro “Raquel Camba” y colabora en revistas y periódicos en
      forma permanente.
    </p>
    <p>
      7 de julio de 1923: Nació en El Galpón, provincia de Salta, el guitarrista
      Eduardo Falú. Mundialmente conocido por su obra musical y por su calidad
      interpretativa. Compuso con Jaime Dávalos, Manuel J. Castilla, Ernesto
      Sábato, César Perdiguer, José Ríos, Hugo Alarcón, entre muchos otros
      poetas. Entre sus éxitos más reconocidos los siguientes temas: La
      Atardecida, La niña, La nostalgiosa, Las golondrinas, La volvedora, No te
      puedo olvidar, Tabacalera, Tonada del viejo amor, Zamba de la Candelaria,
      y otros.
    </p>
    <p>
      7 de julio de 1963: Resultó electo el dostor Illia con el 25% de los
      votos. El peronismo estaba proscripto. En Salta, triunfó la Fórmula
      Ricardo Joaquín Durand – Paz Chain, Eduardo.
    </p>
    <p>
      8 de julio de 1909: Murió en Salta, Luis Avelino Costas, quien fue
      ministro de gobierno.
    </p>
    <p>
      8 de julio de 1955: Nació en San Ramón de la Nueva Orán, provincia de
      Salta, Santos Vergara. En 1982 fundó junto a otros artistas el Grupo
      Vocación de Orán. En 1985 egresó como profesor de Letras de la Universidad
      de Salta, Sede Regional Orán. Publicó con Eduardo Pereyra, el libro de
      cuentos “Las Ausencias” en 1985. Colabora permanentemente en diarios y
      revistas del país.
    </p>
    <p>
      9 de julio de 1853: Salta prestó juramento a la Constitución Nacional y
      nombró como primer gobernador constitucional al brigadier Rudencio
      Alvarado, el 9 de mayo de 1854.
    </p>
    <p>
      9 de julio de 1877: Asumió como gobernador de Salta, el coronel Juan Solá.
    </p>
    <p>
      9 de julio de 1881: Asumió como gobernador de Salta, el doctor Miguel S.
      Ortiz.
    </p>
    <p>
      9 de julio de 1883: Asumió nuevamente como gobernador de Salta, el coronel
      Juan Solá
    </p>
    <p>
      9 de julio de 1886: Asumió el mando como gobernador electo, el doctor
      Martín Miguel de Güemes, único hijo del primogénito del general Martín
      Miguel de Güemes. Tenía sólo 28 años de edad.
    </p>
    <p>
      9 de julio de 1901: Nació en Cachi, Julio Adolfo Vera Alvarado. Fue
      Intendente de Cachi por tres períodos y Diputado por La Poma. Falleció a
      los 93 años. Fue propulsor y fundador del Servicio Cachi – La Poma –
      Molinos.
    </p>
    <p>
      9 de julio de 1921: Fundación de Campo Quijano, llamado El Portal de Los
      Andes.
    </p>
    <p>
      9 de julio de 1927: Nació en los Valles Calchaquíes, Juan José Coll.
      Publicó: Tres meses de cuartel (1952); Otros días (1953); Romancero
      Calchaquí (1959); Sonetos de Otoño (1978). Poeta de gran voz. Es además,
      abogado, productor y empresario.
    </p>
    <p>
      9 de julio de 1970: Se fundó en Villa San Lorenzo, Salta, el Club
      Deportivo y Social “La Esperanza”.
    </p>
    <p>
      10 de julio de 1815: Güemes contrajo enlace en la ciudad de Salta con doña
      Carmen Puch. De ese matrimonio, nacieron tres hijos: Martín, Luis e
      Ignacio. Este último murió en la infancia.
    </p>
    <p>
      10 de julio de 1824:&nbsp;Falleció en Chicoana, Norberto Caro, nacido en
      Chicoana a fines del siglo XVIII, donde se dedicó a las faenas
      agropecuarias. Aportó gruesas sumas de dinero para el mantenimiento de las
      tropas que pasaron por Salta rumbo al Alto Perú. Teniente del 1º Escuadrón
      de Gauchos de Chicoana y después de la muerte de Güemes siguió prestando
      servicios a las órdenes del general Gorriti hasta alcanzar el grado de
      coronel.
    </p>
    <p>
      10 de Julio de 1825: Falleció en Salta, Luis Bernardo Echenique. Había
      nacido en Salta en 1764. Se recibió de abogado y se doctoró en Derecho en
      1788 en Córdoba. Luego en Chuquisaca se ordenó de sacerdote en 1789. En
      1808 regresó a Salta, cuando era creado el nuevo obispado, para colaborar
      con el primer prelado Monseñor Videla el Pino, quien le asignó la
      Parroquia de La Caldera, en ese entonces de gran importancia. Fue gran
      amigo del Deán Gregorio Funes y del Prebístero doctor Juan Ignacio
      Gorriti. Adhirió al movimiento revolucionario de 1810, prestando de
      acuerdo, en su carácter de cabildante de la ciudad de San Salvador de
      Jujuy. Fue capellán del Ejército de Güemes.
    </p>
    <p>
      10 de julio de 1940: Se asienta en la provincia de Salta, la primera
      unidad de Gendarmería Nacional, el Escuadrón Salta, con asiento en la
      ciudad de Embarcación. Se inactiva el 8 de marzo de 1941.
    </p>
    <p>
      11 de julio de 1814: El comandante Zabala, del ejército de Güemes, derroto
      a los realistas, en La Merced, provincia de Salta.
    </p>
    <p>
      11 de julio de 1874: Se inauguró el seminario de la Diócesis de Salta, con
      el nobre de San Buenaventura y bajo la protección de la Virgen. Fue
      designado director el Obispo auxiliar de Monseñor Miguel Moisés Aráoz y
      vice director el canónigo Policarpo Segovia. Asistieron los aspirantes de
      toda la diócesis, entre los cuales estaba el alumno tucumano Bernabé
      Piedrabuena, quien llegaría a ser Obispo diocesano de Tucumán.
    </p>
    <p>
      11 de julio de 1903: Nació en Salta, Lucio Alfredo Cornejo. En 1928 se
      graduó de abogado. Fue fiscal de Estado. En 1946 fue electo gobernador de
      la provincia. Murió en Salta el 26 de diciembre de 1962.
    </p>
    <p>
      11 de julio de 1927: Nació en Salta, Cristóbal Capó Martearena, quien se
      destacó como humorista. Con su Politerma Park recorrió buena porción del
      país llevando su alegría a los pueblos. Era conocido como el Doctor
      Chalita.
    </p>
    <p>
      11 de julio de 1929: Nació en Rojas, Buenos Aires, Agustín Arévalo.
      Sacerdote, docente en la sede regional Orán de la Universidad Nacional de
      Salta. Capellán de la iglesia de Hipólito Irigoyen, Salta. Incluído en
      Antología de Orán.
    </p>
    <p>
      12 de julio de 1958: Nació en Rauch, provincia de Buenos Aires, Daniel
      Darío Argüello. En 1982 se recibe de médico, en la Universidad Nacional de
      La Plata. En 1986 se radica en Orán donde se desempeña en el Hospital San
      Vicente de Paul.
    </p>
    <p>
      12 de julio de 1960: Nació en Cafayate, Ricardo Jesús Strisich, quien es
      el actual intendente de Cafayate, en la provincia de Salta. Inició su
      carrera política como concejal de Cafayate.
    </p>
    <p>
      13 de julio de 1860: El Sumo Pontífice designaba Obispo de Salta a Fray
      Buenaventura Rizo Patrón, llegando la noticia a Salta cuando el Vicario
      capitular don Isidoro Fernández encontraba en plena labor.
    </p>
    <p>
      13 de julio de 1874: Murió en Buernos Aires, en completa miseria, Miguel
      Otero. Nació en Salta en 1790. Estudió en Córdoba y Chuquisaca, donde
      encabezó un movimiento revolucionario en noviembre de 1810, en apoyo de la
      causa de mayo. Perseguido por el coronel español Goyeneche huyó a Salta
      sin doctorarse en jurisprudencia. Años después se traslado al Perú, y en
      Pasco se dedicó al laboreo de minas, adquiriendo una respetable fortuna.
      Colaboró con San Martín en 1820. Más adelante colaboró con Bolívar. A
      fines de 1840 fue desterrado del Perú y confiscadas sus minas de Pasco,
      volvió a Salta, donde en febrero de 1841 fue nombrado gobernador interino,
      cargo que ocupó hasta junio, cuando tuvo que huir a Chile ante el avance
      de las tropas de Lamadrid. Al momento de su muerte, embargadas sus ricas
      minas y sus propiedades, sólo le quedaba en su haber el nombramiento de
      Capitán de Caballería y la Orden del Sol que le diera San Martín en mérito
      a sus servicios en el Perú.
    </p>
    <p>
      13 de julio de 1943: Fue declarada, Monumento Histórico por Decreto Nº
      2.478, La Chacra El Carmen de Güemes. Pertenecía a don Pantaleón Aguirre,
      pasando luego por herencia a sus cuatros hijos: Anselmo, Félix, Isabel y
      María del Milagro. En agosto de 1817 el gobernador de la provincia,
      coronel Martín Miguel de Güemes, se las compró a los herederos, según
      consta en la escritura registrada ante el escribano Félix Ignacio Molina.
      De esa época se conoció como Chacra El Carmen de Güemes, en homenaje a la
      esposa del gobernador. Sirvió de campamento para la caballería gaucha. En
      1821, a la muerte del general Güemes, la propiedad pasó a poder de sus
      hijos Luis y Martín. Después la heredó el doctor Adolfo Güemes, nieto del
      prócer, quien fue el último propietario de la histórica finca, ya que la
      cedió al Gobierno Nacional para que se creara una escuela de ganaderíay
      agricultura. En enero de 1945 el Poder Ejecutivo Nacional aceptó la
      donación. Está ubicada entre la ciudad de Salta y Estación Alvarado. Allí
      funciona la Escuela Agrícola Martín Miguel de Güemes.
    </p>
    <p>
      13 de julio de 1970:&nbsp;Se fundó en la ciudad de Salta, el Sanatorio
      Modelo.
    </p>
    <p>
      14 de julio de 1821: Sucedió en Salta entre los jefes de los ejércitos
      patriotas y realistas. Suscripto por Carlos Chávez, Agustín Dávila y el
      doctor Facundo de Zuviría, por los primeros, y por los segundos, Pedro
      Antonio de Olañeta, Antonino Fernández Cornejo y Saturnino Saravia. A
      pocos días de la muerte del general Martín Miguel de Güemes, se firmó un
      tratado de límites entre Salta y el general realista José Antonio de
      Olañeta.
    </p>
    <p>
      14 de julio de 1923: Nació en Santa Fe, Rodolfo Argenti, estudió en la
      escuela superior de Bellas Artes “Ernesto de la Cárcova” de la Capital
      Federal donde se graduó en 1949 como profesor Superior de Escultura. Llegó
      a Salta en 1952 convocado como muchos otros por Carlos Luis García Bes y
      formó parte del primer cuerpo de profesores de la Escuela Provincial de
      Bellas Artes “Tomás Cabrera”, llegando a Director de la misma. Realizó
      muestras individuales y colectivas. Ganó numerosos premios.
    </p>
    <p>
      14 de julio de 1941: Fue declarada, Monumento Histórico por decreto Nº
      95.687, La finca de la Cruz. El propietario de la finca era don José
      Francisco de Tineo, padrastro de Güemes, por haberse casado con su madre
      al enviudar. El dueño se la donó a Napoleón Güemes y de éste pasó a sus
      dos hijos. Tiempo después Adela Güemes adquiere la parte de su hermano
      quedando como única dueña. En 1887 la compró don Francisco Nemesio Costa,
      quien vivió en ella hasta 1918, año en que pasó a don Belisario Benítez.
      La propiedad pasó después a poder de doña Hermelinda Gauffin de Benítez y
      sus cuatro hijos. El héroe durante los años 1816 a 1821, en que fue
      gobernador de Salta, usaba esta casa como cuartel general y en muchas
      ocasiones como residencia del gobierno y punto de partida de las
      patrióticas proezas de Güemes. Se encuentra sobre el camino de La Cruz al
      sudeste de la ciudad de Salta.
    </p>
    <p>
      14 de julio de 1941: Fue declarada, Monumento Histórico por decreto Nº
      95.687, la Catedral de Salta, ubicada en la calle España entre Zuviría y
      Mitre, frente a la plaza 9 de julio. En 1858 se inició la construcción de
      la actual Catedral, los planos los realizó el profesor Juan Soldati, como
      director general el sacerdote Isidoro Fernández y como mayordomo Salvador
      Cabrera. Quedó terminada en 1882. El obispo Miguel Moisés Aráoz consagró
      este templo el 13 de octubre de 1878. En esta Catedral está el Panteón de
      las Glorias del Norte, que guarda los resto de los generales Martín Miguel
      de Güemes, Rudencio Alvarado, Juan Antonio Alvarez de Arenales, los de
      Monseñor José Gregorio Romero y el doctor Facundo de Zuviría. El 20 de
      abril de 1934, por bula de Pío XI, fue elevada a Catedral Metropolitana,
      al crearse la Arquidiócesis de Salta.
    </p>
    <p>
      14 de julio de 1941: Fue declarado, Monumento Histórico por decreto Nº
      95.687, el Convento de San Bernardo. En 1846 llegaron las monjas
      carmelitas para fundar un beaterio, el Nuevo Carmelo de San Bernardo. Es
      la más vieja de las construcciones religiosas de la provincia de Salta. La
      fachada fue restaurada por el arquitecto mario J. Buschiazzo.
    </p>
    <p>
      14 de julio de 1941: Fue declarada, Monumento Histórico por decreto Nº
      950687, la iglesia de San Francisco. En la calle Caseros y Córdoba de la
      ciudad de Salta. La actual es la tercera construcción que se inició el 17
      de septiembre de 1759. En esta iglesia el 13 de marzo de 1813, el general
      Belgrano acompañado por los cabildantes: Mariano Boedo, Guillermo
      Ormachea, Manuel López, Pedro Pablo Arias Velázquez y Gabriel César,
      asistieron a la misa en memoria de los vencedores y vencidos de la Batalla
      de Salta.
    </p>
    <p>
      14 de julio de 1941: Fue declarado Monumento Histórico Nacional, por
      decreto Nº 95687, el Fuerte de Cobos. Está en el Valle de Siancas, en la
      provincia de Salta.
    </p>
    <p>
      14 de julio de 1941: Fue declarada Monumento Histórico por decreto Nº
      95.687, La Posta de Yatasto, ubicada entre Metán y Rosario de la Frontera,
      en la provincia de Salta. Escenario de históricas entrevistas.
    </p>
    <p>
      14 de julio de 1941:&nbsp;Fue declarada Monumento Histórico, por decreto
      95.687, la Finca de Castañares. En ella pernoctó el general Belgrano,
      cuando se preparaba para la Batalla de Salta.
    </p>
    <p>
      14 de julio de 1941: Fue declarado Lugar Histórico, el Campo de
      Castañares, al norte de la ciudad de Salta, donde sucedió la Batalla de
      Salta.
    </p>
    <p>
      14 de julio de 1953: Falleció Vicente Arias. Nació en Salta en 1868. Se
      graduó de abogado en la Universidad de Buenos Aires. Fundó la Junta de
      Historia de Salta, desde la cual propició en 1938 la primer “Reunión de
      Historia del Noroeste”. Falleció a los 85 años.
    </p>
    <p>
      14 de julio de 1961: Fue designado primer obispo de la Dióesis de la Nueva
      Orán y tomó posesión el 21 de octubre de ese año, Fray Francisco Muguerza,
      que pertenecía a la oreden franciscana. Este sacerdote había nacido en
      vicuña Mackenna, en la provincia de Córdoba el 23 de agosto de 1921.
    </p>
    <p>
      14 de julio de 1986:&nbsp;Falleció en Salta, el académico de número de la
      Academia Güemesiana, don Juan Manuel de Ríos, quien se desempeñaba como
      vice presidente de la corporación. A los 92 años y tras una dedicación
      constante a la investigación sobre Güemes.
    </p>
    <p>
      15 de julio de 1958: Nació en Colonia Santa Rosa, Pedro Morales González,
      quien fue director general de Administración del Senado. Fue electo
      diputado con 25 años. Fue Secretario de Estado de Seguridad Social desde
      1985,a 1986, Presidente de la Caja de Previsión Social desde 1986 a 1988,
      Concejal de 1990 a 1991. Congresal del Partido Justicialista por Capital.
      Secretario Político del bloque del Partido Justicialista de la Cámara de
      Diputados. Congresal Nacional del partido Justicialista por Salta. Fue
      Secretario de la Gobernación de Desarrollo Social.
    </p>
    <p>
      16 de julio de 1957: Nació en General Baldissera, provincia de Córdoba,
      María del carmen Tinari, quien se recibió de médica en 1981. Hizo
      residencia en Córdoba, luego se desempeñó en el Hospital San Vicente de
      Paul de Orán, en la provincia de Salta. En la actualidad reside en las
      Termas de Río Hondo, provincia de Santiago del Estero, donde reside.
    </p>
    <p>
      17 de julio de 1910: Nació en Coronel Moldes, Salta, Raúl Alejo Medrano:
      Militó en el gremialismo. Secretario general de la Unión Obrera
      Metalúrgica. Fundador y presidente del centro vecinal Villa Castañares.
      Falleció en Salta el 16 de junio de 1971.
    </p>
    <p>
      17 de julio de 1988: Falleció el artista Osvaldo Juane, quien realizó una
      importante carrera en nuestra provincia, como pintor y como docente.
    </p>
    <p>
      18 de julio de 1957: Nació Nora Giménez. Licenciada en letras que ocupó
      importantes cargos en la función política como directora de Promoción
      Social; Directora de Control y Gestión del Ministerio de Bienestar Social;
      Secretaria de la Cooperadora Asistencial de Salta (Capital); Asesora del
      Ministerio de Bienestar Social; Directora de la Comisión Bicameral
      Examinadora de obras de Autores Salteños; Coordinadora de programas
      educativos.
    </p>
    <p>
      18 de julio de 1980: Murió en Salta, el poeta Manuel J. Castilla. Publicó
      en libros los siguientes títulos: «Agua de lluvia», «Luna muerta», «La
      niebla y el árbol», «Copajira», «La tierra de uno», «Norte adentro», «El
      cielo lejos», «Bajo las lentas nuebes», «Posesión entre pájaros», «Andenes
      al ocaso», «El verde vuelve», «Cantos del gozante», logró el Premio
      Nacional. Aportó su talento creativo a la Canción Argentina. Reconocido
      como uno de los grandes de la poesía de Salta y de la patria.
    </p>
    <p>
      19 de julio de 1928: Juan Alfonso Carrizo visitó Salta. Estuvo en la
      biblioteca provincial Victorino de la Plaza. Ya había publicado El
      Cancionero popular de Catamarca. Tenía en preparación el de Jujuy y
      trabajaba sobre el de Salta.
    </p>
    <p>
      19 de julio de 1934: Se fundó el Club Social General San Martín. Su
      primera sede estuvo ubicada en la calle 20 de Febrero al 150. Allí se
      construyó la primera cancha de pelota a pelota que hubo en Metán,
      provincia de Salta.
    </p>
    <p>
      19 de julio de 1947: Nació en Rosario de Lerma, Alberto Javier Alderete.
      Egresó de la Universidad del Norte “Santo Tomás de Aquino”, en San Miguel
      de Tucumán, con el título de abogado. Fue asesor de la Honorable Cámara de
      Senadores de la provincia y asesor letrado de la municipalidad de Rosario
      de Lerma. El 30 de septiembre de 1975 fue designado juez de instucción a
      cargo del Juzgado de Instrucción de 2º nominación, del distrito judicial
      del centro, hasta el 14 de abril de 1976. Con el advenimiento de la
      democracia el 10 de diciembre de 1983, el gobernador Roberto Romero lo
      designó jefe de la policía. El 22 de noviembre de 1985 fue designado
      Intendente municipal de Salta, luego ministro de gobierno, justivia y
      educación. Fue vocal del directorio del Banco Provincial de Salta.
      Luego,el primer Intendente Electo de la ciudad de Salta, cargo que ejerció
      desde el 10 de diciembre de 1987 hasta 1991.
    </p>
    <p>
      19 de julio de 1961: Nació en la ciudad de Salta, Martín Federico Grande,
      periodista que conduce el programa despertar por canal 11 y el programa
      radial Martín a lo Grande, por Aries frecuencia modulada.
    </p>
    <p>
      20 de julio de 1868: Nació en Salta el teniente general José Félix
      Uriburu. fue presidente desde el 6 de septiembre de 1930 hasta el 20 de
      febrero de 1932. Murió en París (Francia) el 29 de abril de 1932.
    </p>
    <p>
      20 de julio de 1886: Don Enrique Lona denunció que el cura párroco de
      Metán de 1879 a 1881, descuido los asientos en los libros de bautismos,
      casamientos y defunciones, causando perjuicios a las familias al faltar
      los comprobantes, por lo que solicita al actual párroco (1886), informe al
      Consejo Municipal si quedaron borradores de los asientos faltantes.
    </p>
    <p>
      20 de julio de 1911:&nbsp;Murió en la pobreza José María Juárez Arze.
      Nació el 8 de febrero de 1852, en Salta. Realizó estudios sobre
      enfermedades regionales. Se trasladó a los Valles Calchaquíes donde se
      entregó de lleno a la profesión médica. Fue publicando los siguientes
      títulos en colaboraciones periodísticas y revistas médicas: “Psicopatía e
      hipocondría”, Neurastenias y manías” (1885), “El bacilo de loffer”, “El
      bocio y el medio de combatirlo”, “La fiebre de Malta” y otros temas.
    </p>
    <p>
      20 de julio de 1954: Nació en Salta, Julio Argentino San Millán. Abogado
      que fue Senador Provincial, Ministro de Bienestar Social, Presidente del
      Senado Provincial y en la actualidad es Senador Nacional por Salta.
      Importante dirigente del Partido Justicialista de Salta.
    </p>
    <p>
      21 de julio de 1759: Nació Manuela Martínez de Gangas y Tineo. Hija del
      entonces gobernador de Salta del Tucumán. Se dedicó a la docencia y fundó
      un colegio de huérfanas.
    </p>
    <p>
      21 de julio de 1950: Se creó la Esculela Provincial de Bellas Artes “Tomás
      Cabrera” en la ciudad de Salta.
    </p>
    <p>
      21 de julio de 1954: Nació Marcelo Eduardo Arancibia. Abogado que se
      destacó como dirigente de entidades culturales, folklóricas y deportivas.
      Trabajó incansablemente por el club de sus amores, Atlético Central Norte.
    </p>
    <p>
      22 de julio de 1889: Falleció en la Capital Federal, José Gregorio Lezama,
      nacido en Salta en 1802. Fue proveedor del ejército nacional durante la
      guerra con el Paraguay. Luego de su muerte su esposa transfirió el parque
      y mansión a la Municipalidad de la ciudad de Buenos Aires mediante un
      precio irrisorio, pero condicionado a que el inmueble llevara el nombre
      del extinto esposo y la residencia fuese destinada a MUSEO/. En virtud a
      tal pacto funciona allí el Museo Histórico Nacional y el predio que lo
      rodea se llama hoy Parque Lezama.
    </p>
    <p>
      22 de julio de 1938: Nació en Córdoba (Capital) el escritor y poeta Vázner
      Castilla, pero considera a Cafayate su pueblo natal; hijo de Juan José
      Vázquez y Lorena Martínez. Autor de obras en prosa y poemas e historia:
      Cafayate, Tiempo Presente, Aguary en Ocho Tiempos, Paisajes, Facetas,
      Crepúsculo, Luz y Sombra, Duraznos del Silencio, Tuyunti y su historia,
      Aguada del Zorro – Historia de la Ciudad de Aguaray – en comunión con
      Mercedes B Salas, Silvia Yavi y Daniel Castillo. Fue Presidente de la
      Comisión Aguada del Zorro. Pertenece al Grupo Vocación de Orán y es
      corresponsal de revista La Gauchita en Aguaray, Salvador Mazza y Tartagal.
    </p>
    <p>
      23 de julio de 1778: Murió en San José de Cerrillos, don Mateo de los
      Ríos, quien había nacido en Orán, provincia de Salta, en 1796. En 1814 se
      unió al ejército auxiliar del norte. Cursó en la academia de cadetes en
      Tucumán. Formó parte del escuadrón de gauchos comandados por el general
      Güemes. En 1821 fue ascendido a sargento mayor. Cuando Gorriti fue
      nombrado gobernador de Salta, nombró a Mateo de los Ríos teniente
      gobernador de Orán. Fue tomado prisionero por los realistas y confinado a
      Chuquisaca, de donde doña Toribia (a) “La Linda” le ayudó a fugarse
      disfrazado de sacerdote en 1823. Por 2 vez fue designado teniente
      gobernador de Orán. En misión de servicio perdió la vista, por lo que tuvo
      que solicitar el retiro de la actividad castrense. El 17 de diciembre de
      1882 fue incluído en la lista especial de guerreros de la Independencia.
      Una calle de Orán lleva su nombre.
    </p>
    <p>
      23 de julio de 1888: Nació en Salta, Sara Solá. Desde muy joven reveló sus
      dotes publicando con el seudónimo Violeta del Valle, en los órganos
      periodísticos salteños: Güemes, Nueva Epoca, El Pueblo, El Intransigente,
      La Gaceta, Caras y Caretas, El Hogar. Se casó con el doctor Juan José
      Castellanos en 1911. En 1923 public´su primer libro: Elogio de la vida
      provinciana, que mereció elevados calificativos de Ricardo Rojas, Gustavo
      Martínez Zuviría, Adela Zamudio, Juana de Ibarburu y otros. Algunas de sus
      obras: Reseña tradicional e histórica del Señor y la Virgen del Milagro,
      Poema del Señor del Milagro, La esposa del oidor, La mujer salteña, El
      cabildo, Plegaria a la Santísima Virgen, la actuación del Virrey Toledo en
      la fundación de Salta, el gobernador de Tucumán, Brigadier general don
      Juan Victorio Martínez de Tineo, Biografía del coronel don José de Moldes,
      La victoria del 20 de febrero, El gobernador Manuel Solá Tineo,
      Pronunciamiento de Salta contra la tiranía de Rosas, Don Eustoquio Frías,
      Biografía del general Belgrano. Fue miembro de número del Instituto San
      Felipe y Santiago de Estudios Históricos de Salta y del Instituto
      Belgraniano. Su último libro de poemas Florilegio del Milagro y Santoral,
      publicado en 1973 con prólogo de monseñor Miguel Angel Vergara. Participó
      en muchas entidades de bien público. Murió en Salta, el 3 de julio de
      1978, 20 días antes de cumplir 90 años de edad.
    </p>
    <p>
      23 de julio de 1888: El Presidente de la República Argentina, doctor
      Juárez Celman y el ministro Eduardo Wilde, firmaron un decreto por el cual
      se fundó la actual ciudad de General Güemes, en la provincia de Salta. En
      1950 se elevó a departamento, siendo esta ciudad su cabecera.
    </p>
    <p>
      23 de julio de 1918: Se hizo cargo del gobierno de Salta, en forma
      interina, el coronel Ricardo Solá, hasta que llegó el nuevo interventor
      Federal, Doctor Manuel Carlés.
    </p>
    <p>
      23 de julio de 1934: Nació en Alemania, Ursula Emmy Tony Magerfleisch
      Bossart. Radicada desde joven en la ciudad de Salta. Dirige el Instituto
      Moderno de Alemán. Fue secretaria consular durante 12 años y es profesora
      de idioma desde hace 30 años.ss
    </p>
    <p>
      24 de julio de 1814: El capitán Olivera, del ejército de Güemes, derrotó a
      los realistas, en Cobos, provincia de Salta.
    </p>
    <p>
      24 de julio de 1923: Nació en Salta, Arturo César Wayar Tedín. Desde joven
      se dedicó al teatro. Ejerció la dirección del teatro vocacional de la
      provincia y fue asesor teatral de la dirección de cultura de Salta desde
      1954 a 1965. Trabajó en la adaptación televisiva en La Tierra en Armas
      (1960). En 1952 obtuvo el primer premio, al mejor teatro del interior,
      otorgado por la secretaría de cultura de la nación, con Pachamama. Fue
      intendente municipal de Cachi. Murió en Salta, el 29 de febrero de 1980.
    </p>
    <p>
      25 de julio de 1782: Nació en Salta, Mariano Boedo. Obtuvo el título de
      Licenciado en leyes civiles en 1804 en la Universidad de Chuquisaca. Fue
      designado secretario de la real audiencia de Charcas. Continuó estudiando
      hasta lograr el título de abogado. Cuando el coronel Juan martín de
      Pueyrredón renunció, Mariano Boedo asumió supletoriamente el cargo de
      gobernador de Córdoba. Años después, el general Belgrano le confió la
      tesorería del gobierno salteño. Cuando el Congreso fue trasladado a Buenos
      Aires, Boedo fue elegido presidente del mismo. Falleció el 9 de abril de
      1819.
    </p>
    <p>
      25 de julio de 1896: Falleció en Salta, el doctor en Jurisprudencia
      Benjamín Zorrilla, quien había nacido en Chuquisca el 25 de marzo de 1840.
      Fue electogobernador de Salta por el período 1869 – 1871. Durante su
      gobierno las calles de la ciudad de Salta, se iluminaban con faroles a
      querosene, que se extraía del petróleo, que se producía en la zona de
      Orán. Ocupó otros importantes cargos públicos.
    </p>
    <p>
      25 de julio de 1925: Nació en Salta, Ramiro Dávalos, hijo de don Juan
      Carlos Dávalos. Inició sus estudios de plástica con el artista Carlos
      García Bes en el taller de calle Zuviría al 300. Se trasladó a Tucumán
      becado por la legislatura donde cursó estudios con el pintor Argentino
      Lino Eneas Spinlimbergo entre los años 1948 – 1951, en el Instituto
      Superior de Artes de la Universidad Nacional de Tucumán. Fue docente de la
      Escuela de Bellas Artes “Tomás Cabrera” que creara Pajita García Bes.
      Participó en numerosas exposiciones colectivas e individuales.
    </p>
    <p>
      26 de julio de 1856: Nació en Salta José María Cabezón. Se graduó de
      médico en 1881. Ingresó al ejército como médico castrense. Le fueron
      entregados los despachos de coronel de Sanidad. Activo militante católico.
      Publicó algunos trabajos sobre medicina. Falleció el 11 de diciembre de
      1917 en la Capital Federal.
    </p>
    <p>
      26 de julio de 1903: Murió en Pulares, Chicoana, Salta, Francisco Javier
      Alsina. Nacido en Salta en 1855. Egresó de la Escuela Normal de Paraná
      como profesor normal. Fundó en 1888 la Escuela Normal de varones, actual
      Escuela Normal Mixta. Fue inspector general de escuelas y presidente del
      Consejo General de Educación. Publicó una revista pedagógica: “El amigo de
      la infancia”.
    </p>
    <p>
      26 de julio de 1932: Nació en Salta, Eduardo Figueroa. Desde joven ejerció
      el periodismo en diario El Tribuno. Fue Diputado Provincial. Actualmente
      colabora con las autoridades de la CGT, filial Salta. Es el presidente del
      Partido Laborista de Salta.
    </p>
    <p>
      26 de julio de 1952: Falleció María Eva Duarte de Perón, produciendo un
      dolor que se hizo popular. En Salta una plazoleta perpetua su nombre en la
      intersección de Avenida Entre Ríos y República de Siria.
    </p>
    <p>
      27 de julio de 1874: Nació en Salta, Abraham Cornejo. Segraduó de abogado
      y se doctoró en Jurisprudencia en 1898. Fue fiscal de crimen, director del
      archivo, juez de comercio, fiscal de la corte de justicia, juez federal en
      Salta. El 20 de febrero de 1916 asumió como gobernador en Salta. El 1º de
      mayo de 1940 asumió por 2º vez como gobernador. No pudo completar tampoco
      este mandato por haberle sobrevinido la muerte. Realizando gestiones
      oficiales en la Capital Federal, cayó enfermo y regresó a su provincia por
      vía ferroviaria. Falleció al pasar por Tucumán, el 1º de diciembre de
      1941.
    </p>
    <p>
      27 de julio de 1951:&nbsp;Se fundó la Asociación de Clínicas y Sanatorios
      privados de la provincia de Salta. Existían 4 establecimientos
      asistenciales en Salta: Sanatorio El Carmen, Sanatorio San Roque, Clínica
      Salta y Clínica Güemes, siendo todos fundados y de propiedad de médicos.
      La primera comisión de fundadores estuvo integrada por los doctores Gaspar
      J. Solá Figueroa y Juan Carlos Escudero. (Se reunieron los doctores
      Nolasco F. Cornejo, Marcos A. Gutiérrez, Raúl A. Caro, Héctor Mario Vila y
      los nombrados anteriormente).
    </p>
    <p>
      27 de julio de 1972: Falleció monseñor Miguel Angel Vergara. hasta 1935
      había cumplido su sagrado ministerio y su labor de historiados en la
      provincia de Jujuy. Publicó obras históricas. Creado el Arzobispado de
      Salta fue destinado allí con el cargo de Deán de la catedral y vicario
      historiador: fue académico corresondiente en Salta de la Academia Nacional
      de la historia y miembro titular del instituto de San Felipe y Santiago de
      Estudios Históricos de Salta, cuya comisión directiva presidió. Fue el
      primer director del MUSEO/ Regional del Norte, dependiente de la comisión
      nacional de MUSEOS y de monumentos y lugares históricos.
    </p>
    <p>
      28 de julio de 1842: El gobernador delegado de Salta, Manuel Antonio
      Saravia, federal, derrotó al coronel Florentino Santos, unitarios, que
      invadió el país, procedente de Bolivia, al mando de fuerzas armadas, en
      Rumiguasi o Rumihuasi, provincia de Salta.
    </p>
    <p>
      28 de julio de 1879: Falleció en la finca de San Agustín (Salta),
      Francisco Centeno, oriundo de Cerrillos, nació en 1816. Acrecentó su
      fortuna en el comercio de mulas con Bolivia y Perú. Participó en el
      “Combate del Río Arias” y en la “Defensa de Salta contra Felipe Varela”.
    </p>
    <p>
      28 de julio de 1912:&nbsp;Se fundó la Asociación Alianza Israelita de
      Socorros Mutuos de Salta.
    </p>
    <p>
      29 de julio de 1861: El gobernador de Salta coronel mayor Anselmo Rojo,
      presentó su renuncia, siéndosele aceptada y haciéndose cargo el presidente
      de la legislatura, el doctor Moisés Oliva, quien se mantuvo en el cargo
      hasta el 30 de agosto, fecha en que fue elegido don José María Tood.
    </p>
    <p>
      29 de julio de 1938:&nbsp;Nació en Salta, Néstor Policarpo Ruiz de los
      Llanos. En la Universidad de Córdoba se recibió de médico en 1962. Fue
      director del hospital de Cachi. Trabajó en el Hospital San Bernardo.
      Médico auditor y laboral de Vialidad, Presidente del directorio del
      instituto Médico de Salta. Miembro fundador de la Sociedad de Cardiología
      de Salta. Miembro de la Sociedad de Profesores de Medicina interna.
      Miembro de la Asociación Médica Argentina. Presidente del Círculo Médico
      de Salta (1972 – 1973).
    </p>
    <p>
      30 de julio de 1869: El gobierno de la provincia de Salta, decretó la
      fundación de una escuela en Rosario de la Frontera, la misma se denominó
      Martina Silva de Gurruchaga.
    </p>
    <p>
      30 de julio de 1975: Falleció en Capital Federal, la cantante popular
      nacida en Salta, Alicia Martínez. Una voz que es recordada en su provincia
      natal con mucho respeto.
    </p>
    <p>
      31 de julio de 1807: Don Santiago de Liniers envió a la Corona, la
      información que el cadete Güemes actuó en la defensa de Buenos Aires en
      Las Invasiones Inglesas.
    </p>
    <hr />
    <CommentsFB></CommentsFB>
  </Layout>
)

export default EfeEnero
