import React from "react"
import { Link } from "gatsby"

const EfemeridesM = () => (
  <div className="efe-index-flex">
    <Link to="/libros/efemerides/enero">Enero</Link>
    <Link to="/libros/efemerides/febrero">Febrero</Link>
    <Link to="/libros/efemerides/marzo">Marzo</Link>
    <Link to="/libros/efemerides/abril">Abril</Link>
    <Link to="/libros/efemerides/mayo">Mayo</Link>
    <Link to="/libros/efemerides/junio">Junio</Link>
    <Link to="/libros/efemerides/julio">Julio</Link>
    <Link to="/libros/efemerides/agosto">Agosto</Link>
    <Link to="/libros/efemerides/septiembre">Septiembre</Link>
    <Link to="/libros/efemerides/octubre">Octubre</Link>
    <Link to="/libros/efemerides/noviembre">Noviembre</Link>
    <Link to="/libros/efemerides/diciembre">Diciembre</Link>
  </div>
)

export default EfemeridesM
